@import "commonStyles/commonStyles.scss";
.gymEntracneIntstuction {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: $commonPadding;
  .gymEntracneIntstuctionTitle {
    color: $red-accent;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-top: 120px;
    margin-bottom: 24px;
  }
  .gymEntracneIntstuctiontText {
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 12px;
    text-align: center;
  }
  .gymEntranceInstructionRedItem {
    color: $red-accent;
  }
}
@media (max-width: $xxl) {
  .gymEntracneIntstuction {
    .gymEntracneIntstuctionTitle {
      font-size: 40px;
    }
    .gymEntracneIntstuctiontText {
      font-size: 24px;
    }
  }
}
@media (max-width: $md) {
  .gymEntracneIntstuction {
    .gymEntracneIntstuctionTitle {
      font-size: 32px;
    }
    .gymEntracneIntstuctiontText {
      font-size: 20px;
    }
  }
}
@media (max-width: $sm) {
  .gymEntracneIntstuction {
    .gymEntracneIntstuctionTitle {
      font-size: 24px;
    }
    .gymEntracneIntstuctiontText {
      font-size: 18px;
    }
  }
}
@media (max-width: $xxl) {
  .gymEntracneIntstuction {
    padding: 0 100px;
  }
}
@media (max-width: $xl) {
  .gymEntracneIntstuction {
    padding: 0 75px;
  }
}
@media (max-width: $lg) {
  .gymEntracneIntstuction {
    padding: 0 50px;
  }
}
@media (max-width: $md) {
  .gymEntracneIntstuction {
    padding: 0 24px;
  }
}
