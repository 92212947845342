@import "commonStyles/commonStyles.scss";
.passesSectionWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  .passesSectionInnerWrapper {
    max-width: 1920px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: $commonPadding;
  }
  .passesSectionTitle {
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
  .passesSectionItems {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;
    gap: 32px;
  }
  .passComponent {
    display: inline-flex;
  }
}
@media (max-width: $xxl) {
  .passesSectionWrapper {
    .passesSectionTitle {
      font-size: 40px;
    }
  }
}
@media (max-width: $md) {
  .passesSectionWrapper {
    .passesSectionTitle {
      font-size: 32px;
    }
    .passesSectionInnerWrapper {
      padding: 0;
    }
  }
}
