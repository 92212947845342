@import "commonStyles/commonStyles.scss";
.calendarRowOpeningComponent {
  width: 120px;
  border-bottom: 1px dashed $calendar-gray;
  display: flex;
  align-items: flex-end;
}
.calendarRowOpeningComponentText {
  position: relative;
  left: -60px;
  bottom: -10px;
  color: $white-text;
  font-family: "Black Ops One";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: start;
}
@media (max-width: 870px) {
  .calendarRowOpeningComponent {
    width: 10px;
  }
  .calendarRowOpeningComponentText {
    font-size: 12px;
    left: -40px;
  }
}
@media (max-width: 516px) {
  .calendarRowOpeningComponent {
    width: 2px;
  }
  .calendarRowOpeningComponentText {
    font-size: 12px;
    left: 10px;
  }
}
