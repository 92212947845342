@import "commonStyles/commonStyles.scss";
.mainGalleryImageWrapper {
  max-width: 486px;
  max-height: 326px;
  min-width: 486px;
  min-height: 326px;
  margin: 12px 24px;
  background: linear-gradient(
    135deg,
    #e0090f 0%,
    rgba(224, 9, 15, 0) 22.82%,
    rgba(224, 9, 15, 0) 77.32%,
    #e0090f 100%
  );
  align-items: center;
  justify-content: space-evenly;
  display: inline-flex;
}
.mainGalleryImageWrapper:hover {
  background: $red-accent;
  cursor: pointer;
}
@media (max-width: $md) {
  .mainGalleryImageWrapper {
    max-width: 246px;
    max-height: 166px;
    min-width: 246px;
    min-height: 166px;
  }
  .mainGalleryImage {
    max-width: 240px;
    max-height: 160px;
  }
}
@media (max-width: 620px) {
  .mainGalleryImageWrapper {
    margin: 24px 16px;
    max-height: 100%;
    min-width: 90%;
    min-height: auto;
    max-width: 100%;
    background: none;
  }
  .mainGalleryImageWrapper:hover {
    background: none;
  }
  .mainGalleryImage {
    max-width: 100%;
    max-height: 320px;
  }
}
