@import "commonStyles/commonStyles.scss";
.underline {
  width: 150px;
  height: 6px;
  background: $red-accent;
}

@media (max-width: $xxl) {
  .underline {
    width: 100px;
  }
}
