@import "commonStyles/commonStyles.scss";
.commonButtonStyles {
  border: 4px solid $red-accent;
  border-radius: 40px;
  color: $white-text;
  text-align: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: fit-content;
  position: relative;
  z-index: 9999 !important;
  border-style: outset;
}
.commonButtonStyles:hover {
  cursor: pointer;
  filter: drop-shadow(2px 2px 4px $red-accent);
}
@media (max-width: $md) {
  .commonButtonStyles {
    font-size: 20px !important;
  }
}
