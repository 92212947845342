@import "commonStyles/commonStyles.scss";
.pageFooter {
  margin-top: 160px;
  .footerLogo {
    margin-bottom: 40px;
  }
  .footerWrapper {
    background-color: $white-background;
    margin-top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .footerInnerWrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: -100px;
  }
  .footerConctactUs {
    color: $black-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 20px;
  }
  .footerConctactUsNumbers {
    color: $black-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 20px;
    text-align: center;
  }
  .footerSingleNumber {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    color: $black-text;
  }
  .footerFollowUs {
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 40px;
  }
  .footerFollowUsIcon {
    margin-right: 12px;
    margin-left: 12px;
  }
  .footerFollowUsIcon:hover {
    cursor: pointer;
    filter: drop-shadow(2px 2px 1.5px $red-accent);
  }
  .footerCopyright {
    color: $black-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .footerCopyrightRed {
    color: $red-accent;
  }
}
@media (max-width: $xxl) {
  .pageFooter {
    margin-top: 120px;
    .footerInnerWrapper {
      top: -30px;
    }
    .footerConctactUs {
      font-size: 32px;
    }
    .footerConctactUsNumbers {
      font-size: 24px;
    }
    .footerFollowUs {
      font-size: 32px;
    }
  }
}
@media (max-width: $md) {
  .pageFooter {
    margin-top: 80px;
    .footerLogo {
      width: 200px;
    }
    .footerInnerWrapper {
      top: 0px;
    }
    .footerConctactUs {
      font-size: 24px;
    }
    .footerConctactUsNumbers {
      font-size: 20px;
    }
    .footerFollowUs {
      font-size: 24px;
    }
    .footerFollowUsIcon {
      width: 50px;
      margin-bottom: 24px;
    }
    .footerCopyright {
      font-size: 12px;
    }
  }
}
@media (max-width: $sm) {
  .pageFooter {
    .footerWrapper {
      padding-bottom: 200px;
    }
  }
}
