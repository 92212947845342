@import "commonStyles/commonStyles.scss";
.notFoundPage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notFoundPageMainTitle {
  color: $red-accent;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}
.notFoundPageDescription {
  color: $white-text;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 24px;
}
@media (max-width: $md) {
  .notFoundPageMainTitle {
    font-size: 42px;
  }
  .notFoundPageDescription {
    font-size: 24px;
  }
}
