@import "commonStyles/commonStyles.scss";
.calendarTopLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: gray;
  border-left: 1px dashed $calendar-gray;
  border-bottom: 1px dashed $calendar-gray;
  .calendarTopLabel {
    max-height: 100px;
    width: 100px;
    background-color: $red-accent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 34px 40px;
    color: $white-text;
    font-family: "Black Ops One";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
}

@media (max-width: 1300px) {
  .calendarTopLabelWrapper {
    max-width: 100px;
    .calendarTopLabel {
      padding: 34px 0;
      font-size: 14px;
    }
  }
}
@media (max-width: 768px) {
  .calendarTopLabelWrapper {
    max-width: 60px;
  }
}
@media (max-width: 516px) {
  .calendarTopLabelWrapper {
    max-width: 50px;
  }
}
@media (max-width: 360px) {
  .calendarTopLabelWrapper {
    max-width: 43px;
  }
}
