@import "commonStyles/commonStyles.scss";
.passComponentTitleWrapper {
  background: $white-background;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  border-bottom: 3px solid $red-accent;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
}
.passComponent {
  border-radius: 25px;
  min-width: 320px;
  min-height: 480px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .passComponentTitle {
    z-index: 10;
    width: 100%;
    padding: 16px 12px 4px 12px;
    max-width: 100%;
    color: $black-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bottomVectorRed {
    background-color: $red-accent;
    height: 20px;
    width: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    padding-top: 8px;
  }
  .passComponentMain {
    display: flex;
    align-items: center;
    background-color: $white-background;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding-bottom: 12px;
    .passComponentTopItems {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .passComponentMainFirstPartTitle {
      color: $black-text;
      text-align: justify;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
    .passComponentMainFirstPartCost {
      color: $black-text;
      text-align: justify;
      font-family: "Open Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-bottom: 8px;
    }
    .passComponentMainSecondPartTitle {
      color: $black-text;
      text-align: justify;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
      margin-top: 8px;
    }
    .passComponentMainSecondPartCost {
      color: $black-text;
      text-align: justify;
      font-family: "Open Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-align: center;
    }
    .passComponentMainSubtitle {
      color: $black-text;
      text-align: justify;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 6px 0;
    }
    .passComponentSubtitleSpace {
      height: 1px;
    }
  }
}
@media (min-width: 2000px) {
  .mobileVersionPassComponentTopVector {
    display: none;
  }
}
@media (max-width: 2000px) {
  .mobileVersionPassComponentTopVector {
    position: absolute;
    left: -23px;
    max-width: 100%;
  }
  .passComponentTitleWrapper {
    min-height: 85px;
  }
  .passComponent {
    max-width: 250px;
    min-width: 250px;
    min-height: 390px;
    .passComponentTitleVector {
      width: 100%;
      display: none;
    }
    .passComponentTitle {
      font-size: 18px;
      max-width: 100%;
    }
    .passComponentMain {
      .passComponentMainFirstPartTitle {
        font-size: 16px;
      }
      .passComponentMainFirstPartCost {
        font-size: 24px;
      }
      .passComponentMainSecondPartTitle {
        font-size: 16px;
      }
      .passComponentMainSecondPartCost {
        font-size: 24px;
      }
      .passComponentMainSubtitle {
        font-size: 14px;
      }
    }
  }
}
@media (max-width: $xxl) {
  .passComponent {
    margin-top: 0px;
  }
}
