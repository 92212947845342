@import "commonStyles/commonStyles.scss";
.cardsWrapper {
  margin-top: 64px;
  background-color: $white-background;
  padding: 60px;
  .cardsWrapperTitleComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .cardsWrapperTitle {
    color: $black-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    margin-bottom: 24px;
  }
  .cardsComponent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .cardsWrapperSubTitle {
    color: $black-text;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    text-align: center;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 40px;
  }
}
.medicoverLightBlue {
  margin-left: 20px;
  border-radius: 12px;
  border: 1px solid $black-text;
}
@media (max-width: $xxl) {
  .cardsWrapper {
    padding: 48px 100px;
    .cardsWrapperTitle {
      font-size: 40px;
    }
  }
}
@media (max-width: $xl) {
  .cardsWrapper {
    padding: 48px 75px;
  }
}
@media (max-width: $lg) {
  .cardsWrapper {
    padding: 25px 50px;
  }
}
@media (max-width: $md) {
  .cardsWrapper {
    padding: 24px 24px;
    .cardsWrapperTitle {
      font-size: 32px;
    }
    .cardsComponent {
      flex-direction: column;
    }
  }
  .medicoverLightBlue {
    margin-left: 0;
    margin-top: 30px;
  }
}
