@import "commonStyles/commonStyles.scss";
.gymCoachComponentWrapper {
  width: 250px;
  height: 331px;
  border: 1px solid $white-text;
  position: relative;
  .descriptionComponent {
    background-color: rgba(224,9,14,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 200px;
    position: absolute;
    bottom: 80px;
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .phoneNumberComponent {
    background-color: rgba(224,9,14,0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 150px;
    position: absolute;
    bottom: 40px;
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .defaultCoachIcon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    top: calc(50% - 50px);
    left: 50%;
  }
}
.coachVector {
    width: 100%;
}
