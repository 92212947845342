@import "commonStyles/commonStyles.scss";
.calendarContentWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  color: gray;
  border-left: 1px dashed $calendar-gray;
  border-bottom: 1px dashed $calendar-gray;
  .calendarContentLabel {
    max-height: 100px;
    width: 180px;
    height: 100px;
    background-color: $white-text;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black-text;
    font-family: "Black Ops One";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }
  .calendarTopLabelEmpty {
    max-height: 100px;
    width: 180px;
  }
}
.calendarContentWrapper:hover {
  cursor: pointer;
  filter: drop-shadow(2px 2px 4px $red-accent);
  .calendarContentLabel {
    color: $red-accent;
  }
}
@media (max-width: 1300px) {
  .calendarContentWrapper {
    .calendarContentLabel {
      max-width: 100px;
      width: 100px;
      font-size: 13px;
    }
    .calendarTopLabelEmpty {
      max-width: 100px;
    }
  }
}
@media (max-width: 768px) {
  .calendarContentWrapper {
    .calendarContentLabel {
      max-width: 60px;
      width: 60px;
      font-size: 11px;
      white-space: pre-wrap;
      word-break: break-word;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .calendarTopLabelEmpty {
      max-width: 60px;
    }
  }
}

@media (max-width: 516px) {
  .calendarContentWrapper {
    .calendarContentLabel {
      max-width: 50px;
    }
    .calendarTopLabelEmpty {
      max-width: 50px;
    }
  }
}
@media (max-width: 360px) {
  .calendarContentWrapper {
    .calendarContentLabel {
      max-width: 43px;
    }
    .calendarTopLabelEmpty {
      max-width: 43px;
    }
  }
}
