@import "commonStyles/commonStyles.scss";
.mainGalleryImage {
  max-width: 480px;
  max-height: 320px;
}
.galleryModalOverlay {
  background-color: "black";
}
.mainGalleryGrid {
  margin-top: 200px;
}
.galleryModalContainer {
  position: absolute;
  outline: none;
  border-radius: 16px;
  height: 90vh;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryModalOverlay {
  position: fixed;
  display: flex;
  inset: 0px;
  background-color: rgba(0, 25, 25, 0.5);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 6;
}
.galleryBigPicture {
  max-width: 90vw;
  max-height: 98vh;
}
.galleryAdditionalExit {
  font-size: 70px;
  color: $white-text;
  position: absolute;
  right: 40px;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.galleryAdditionalExit:hover {
  cursor: pointer;
  color: $red-accent;
}

@media (max-width: $md) {
  .galleryModalContainer {
    height: 100vh;
  }
  .galleryBigPicture {
    max-width: 100vw;
    max-height: 100vh;
  }
}
@media (max-width: $sm) {
  .mainGalleryGrid {
    margin-top: 100px;
  }
}
