@import "commonStyles/commonStyles.scss";
.mainPageScreen {
  display: flex;
  justify-content: space-between;
  top: 0px;
  padding: $commonPadding;
  max-width: 1200px;
  @keyframes fadeInLeft {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .textOpacityAnimate {
    opacity: 1;
  }
  .animateText1 {
    animation: fadeInLeft 0.8s ease-in-out forwards;
  }
  .animateText2 {
    animation: fadeInLeft 0.9s ease-in-out forwards;
  }
  .animateText3 {
    animation: fadeInLeft 1s ease-in-out forwards;
  }
  .animateText4 {
    animation: fadeInLeft 1.1s ease-in-out forwards;
  }
  .animateText5 {
    animation: fadeInLeft 1.2s ease-in-out forwards;
  }
  .mainScreenBodyBuilderImage {
    mix-blend-mode: lighten;
    position: absolute;
    top: 200px;
    right: 20px;
    width: 55%;
    max-width: 1300px;
  }
  .mainPageScreenText {
    font-size: 70px;
    font-family: "Black Ops One";
    text-align: start;
    padding: 40px 0;
    z-index: 10;
  }
  .mainPageWhiteColor {
    color: $white-text;
  }
  .mainPageRedColor {
    color: $red-accent;
  }
  .mainPageRedColorSmaller {
    color: $red-accent;
    font-size: 32px;
    margin-top: 32px;
  }
  .mainPageRedColorVerySmall {
    color: $red-accent;
    font-size: 20px;
  }
  .applicationButton {
    margin: 16px 8px;
    cursor: pointer;
    display: none;
  }
  .titleBottomButtons {
    display: flex;
    margin-top: 16px;
  }
}
@media (min-width: $l4k) {
  .mainPageScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    .mainScreenBodyBuilderImage {
      right: 15vw;
    }
  }
}
@media (min-width: 3200px) {
  .mainPageScreen {
    justify-content: flex-end;
  }
}
@media (max-width: $xxl) {
  .mainPageWhiteColor {
    font-size: 60px;
  }
  .mainPageRedColor {
    font-size: 60px;
  }
}
@media (max-width: $xl) {
  .mainPageWhiteColor {
    font-size: 42px;
  }
  .mainPageRedColor {
    font-size: 42px;
  }
  .mainPageScreen {
    .mainScreenBodyBuilderImage {
      width: 65%;
    }
  }
}
@media (max-width: $xxl) {
  .mainPageScreen {
    padding: 0 100px;
  }
}
@media (max-width: $xl) {
  .mainPageScreen {
    padding: 0 75px;
  }
}
@media (max-width: $lg) {
  .mainPageScreen {
    padding: 0 50px;
    .mainScreenBodyBuilderImage {
      width: 75%;
    }
  }
}
@media (max-width: $md) {
  .mainPageScreen {
    padding: 0 24px;
    .mainScreenBodyBuilderImage {
      width: 80%;
      top: 200px;
      opacity: 0.3;
    }
  }
}
@media (max-width: $sm) {
  .mainPageScreen {
    padding: 0 24px;
    .mainScreenBodyBuilderImage {
      width: 100%;
      top: 180px;
      right: 0px;
      opacity: 0.3;
    }
    .mainPageWhiteColor {
      font-size: 32px;
    }
    .mainPageRedColor {
      font-size: 32px;
    }
    .applicationButton {
      display: flex;
    }
  }
  .applicationButton {
    height: 40px;
    margin: 8px 4px !important;
  }
}
@media (max-width: 330px) {
  .applicationButton {
    height: 35px;
    margin: 8px 4px !important;
  }
}
