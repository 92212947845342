@import "commonStyles/commonStyles.scss";
.topHeader {
  opacity: 0;
  height: 70px;
  margin-bottom: 190px;
}
.animateHeader {
  animation: fadeInDown 1.1s ease-in-out;
  animation-delay: 0s;
  opacity: 1;
}
.headerOpacityNormal {
  opacity: 1;
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.topHeaderTextPositions {
  position: relative;
  top: -50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topHeaderTextItems {
  display: flex;
  justify-content: space-around;
  width: 800px;
  .topHeaderTextItem {
    font-size: 18px;
    font-family: "Open Sans";
    text-decoration: none;
    font-weight: 700;
    color: $white-text;
    height: 40px;
    width: 200px;
  }
  .topHeaderTextItem:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
.topHeaderTabletMenu {
  display: none;
}
@media (max-width: $xl) {
  .topHeaderTextPositions {
    top: -42px;
  }
  .topHeaderTextItems {
    width: 750px;
  }
  .topHeaderVector {
    width: 900px;
  }
}
@media (max-width: 920px) {
  .topHeaderVector {
    display: none;
  }
  .topHeader {
    margin-bottom: 130px;
  }
  .topHeaderTabletMenu {
    display: block;
    .burgerMenuStyle {
      position: absolute;
      right: 40px;
      top: 15px;
    }
    .topHeaderTabletMenuContainer {
      width: 200px;
      position: absolute;
      top: 100px;
      z-index: 40;
      right: 0px;
      background-color: $red-accent;
      .topHeaderTabletMenuItem {
        font-size: 18px;
        font-family: "Open Sans";
        text-decoration: none;
        font-weight: 700;
        color: $white-text;
        padding: 12px 12px;
        border: 4px solid $white-text;
        display: block;
      }
    }
  }
  .topHeaderTextPositions {
    top: 0px;
    background-color: $red-accent;
    margin-bottom: 150px;
  }
  .topHeaderTextItems {
    height: 80px;
    display: flex;
    align-items: center;
  }
  .topHeaderTextItem {
    display: none;
  }
  .topHeaderVectorLogo {
    position: relative;
    top: 75px;
    width: 181px;
  }
}
@media (max-width: $sm) {
  .topHeaderVectorLogo {
    width: 120px;
    top: 40px;
  }
  .topHeader{
    margin-bottom: 50px;
  }
}
