@import "commonStyles/commonStyles.scss";
.mobileMenuWrapper {
  display: none;
}

@media (max-width: $sm) {
  .mobileMenuWrapper {
    z-index: 200;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobileMenuVectorElementsLeft {
    margin-left: 2px;
  }
  .mobileMenuVectorElementsRight {
    margin-right: 2px;
  }
  .mobileMenuCentrumSilyLogo {
    position: absolute;
    top: -90px;
  }
  .moileMenuTextContents {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: space-around;
  }
  .mobileMenuText {
    color: $white-text;
    text-align: center;
    font-family: "Black Ops One";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    width: 150px;
  }
  .mobileMenuGalleryLink {
    text-decoration: none;
  }
}
