@import "commonStyles/commonStyles.scss";
.gymCoachesComponent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 200px 50px 200px;
}
.gymCoachesTitle {
  color: $white-text;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
}
.gymCoachesWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 48px 0;
  gap: 24px;
}
@media (max-width: $xxl) {
  .gymCoachesComponent {
    padding: 48px 100px;
  }
  .gymCoachesTitle {
    font-size: 40px;
  }
}
@media (max-width: $xl) {
  .gymCoachesComponent {
    padding: 48px 75px;
  }
}
@media (max-width: $lg) {
  .gymCoachesComponent {
    padding: 25px 50px;
  }
}
@media (max-width: $md) {
  .gymCoachesComponent {
    padding: 24px 24px;
  }
  .gymCoachesTitle {
    font-size: 32px;
  }
}
