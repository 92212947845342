@import "commonStyles/commonStyles.scss";
.statuteMain {
  padding: 0 200px;
}
.statueMainText {
  color: $white-text;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 60px;
  margin-top: 200px;
}
.statueDescription {
  color: $white-text;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  text-align: justify;
}
.statueMainButtons {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
@media (max-width: $xxl) {
  .statuteMain {
    padding: 0 100px;
  }
}
@media (max-width: $xl) {
  .statuteMain {
    padding: 0 75px;
  }
}
@media (max-width: $lg) {
  .statuteMain {
    padding: 0 50px;
  }
}
@media (max-width: $md) {
  .statuteMain {
    padding: 0 24px;
  }
}
@media (max-width: $sm) {
  .statueMainText {
    margin-top: 100px;
  }
}
