@import "commonStyles/commonStyles.scss";
.calendarComponentLastRowBorder {
  height: 16px;
}
.calendarComponentLastRowBorderStart {
  width: 120px;
}
.calendarComponentLastRowBorderEnd {
  width: 16px;
}
.calendarComponentLastRowBorderWidth {
  width: 196px;
}

@media (max-width: 1300px) {
  .calendarComponentLastRowBorderWidth {
    width: 116px;
  }
}
@media (max-width: 870px) {
  .calendarComponentLastRowBorderStart {
    width: 10px;
  }
}
@media (max-width: 768px) {
  .calendarComponentLastRowBorderWidth {
    width: 76px;
  }
}
@media (max-width: 516px) {
  .calendarComponentLastRowBorderWidth {
    width: 66px;
  }
  .calendarComponentLastRowBorderEnd {
    width: 2px;
  }
  .calendarComponentLastRowBorderStart {
    width: 2px;
  }
}
@media (max-width: 360px) {
  .calendarComponentLastRowBorderWidth {
    width: 59px;
  }
}
