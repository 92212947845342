@import "commonStyles/commonStyles.scss";
.selectedTileComponentTitle {
  font-family: "Black Ops One";
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 16px;
}
.selectedTileText {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-top: 6px;
  margin-bottom: 6px;
}
