@import "commonStyles/commonStyles.scss";
.activitiesCalendarWrappper {
  background-image: url("../../../images/CalendarBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.activitiesCalendarContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 200px;
  margin: 0 0 48px 0;
}
.activitiesCalendarTitle {
  color: $white-text;
  text-align: center;
  font-family: "Black Ops One";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-bottom: 24px;
}
@media (max-width: $xxl) {
  .activitiesCalendarContent {
    padding: 48px 100px;
  }
  .activitiesCalendarTitle {
    font-size: 40px;
  }
}
@media (max-width: $xl) {
  .activitiesCalendarContent {
    padding: 48px 75px;
  }
}
@media (max-width: $lg) {
  .activitiesCalendarContent {
    padding: 25px 50px;
  }
}
@media (max-width: $md) {
  .activitiesCalendarContent {
    padding: 24px 24px;
  }
  .activitiesCalendarTitle {
    font-size: 32px;
  }
}
