@import "commonStyles/commonStyles.scss";
.detailsTextContentWrapper {
  max-width: 30%;
  .detailsTextContentHeightWrapper {
    height: 90px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .detailsTextContentTitle {
    color: $black-text;
    font-family: "Black Ops One";
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: start;
    margin-top: 32px;
  }

  .detailsTextContentDescription {
    color: $black-text;
    text-align: justify;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    margin-top: 24px;
    font-weight: 400;
    line-height: normal;
  }
}
@media (max-width: $lg) {
  .detailsTextContentWrapper {
    max-width: 100%;
    margin-right: 0px !important;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
