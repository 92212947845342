@import "commonStyles/commonStyles.scss";
.calendarRowClosingComponent {
  width: 16px;
  border-left: 1px dashed $calendar-gray;
  border-bottom: 1px dashed $calendar-gray;
}
@media (max-width: 516px) {
  .calendarRowClosingComponent {
    width: 2px;
  }
}
